<template>
  <static-template>
    <h1>{{ $t('howWeWork') }}</h1>
    <br />

    <p>{{ $t('motto') }}</p>
    <br />

    <h2>{{ $t('democratize') }}</h2>
    <p>
      {{ $t('hwwSummary1') }}
    </p>
    <p>
      {{ $t('hwwSummary2') }}
    </p>
    <p>{{ $t('hwwSummary3') }}</p>
    <br />

    <h2>{{ $t('howWeOperate') }}</h2>

    <ol>
      <li>
        <h3>{{ $t('hwwTitle1') }}</h3>
        {{ $t('hwwText1') }}
      </li>
      <li>
        <h3>{{ $t('hwwTitle2') }}</h3>
        {{ $t('hwwText2') }}
      </li>
      <li>
        <h3>{{ $t('hwwTitle3') }}</h3>
        {{ $t('hwwText3') }}
      </li>
      <li>
        <h3>{{ $t('hwwTitle4') }}</h3>
        {{ $t('hwwText4') }}
      </li>
      <li>
        <h3>{{ $t('hwwTitle5') }}</h3>
        {{ $t('hwwText5') }}
      </li>
    </ol>
  </static-template>
</template>

<script>
export default {
  name: 'how-page',
};
</script>
